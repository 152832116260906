import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../Styles/CellDetail.css'; // Assurez-vous d'importer le fichier CSS

function CellDetail() {
  const { pageName, cellId } = useParams();
  const [cellData, setCellData] = useState({ comments: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://server.matrix.rtvc-cmonde.com/api/pages/${pageName}/cells/${cellId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Erreur lors du chargement des données de la cellule.');
        }
        return response.json();
      })
      .then(data => setCellData(data))
      .catch(err => setError(err.message));
  }, [pageName, cellId]);

  const handleChange = (e) => {
    setCellData({ ...cellData, comments: e.target.value });
  };

  const handleSave = () => {
    fetch(`https://server.matrix.rtvc-cmonde.com/api/pages/${pageName}/cells/${cellId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(cellData),
    })
      .then(() => {
        alert('Commentaire enregistré');
        navigate(-1); // Redirige vers la page précédente
      })
      .catch((err) => {
        alert(`Erreur lors de l'enregistrement : ${err.message}`);
      });
  };

  return (
    <div className="cell-detail-container">
      <h2>Commentaires pour la cellule {cellId} sur la page {pageName}</h2>
      {error && <p className="error-message">{error}</p>}
      <textarea
        value={cellData.comments || ''}
        onChange={handleChange}
        rows={10}
        cols={50}
        className="textarea"
        placeholder="Écrivez votre commentaire ici..."
      ></textarea>
      <br />
      <button className="button button-save" onClick={handleSave}>
        Enregistrer
      </button>
      <button className="button button-back" onClick={() => navigate(-1)}>
        Retour
      </button>
    </div>
  );
}

export default CellDetail;
