import React, { useState } from 'react';
import '../Styles/CreatePage.css'; // Assurez-vous d'importer le fichier CSS

function CreatePage() {
  const [pageName, setPageName] = useState('');
  const [error, setError] = useState('');

  const handleCreatePage = () => {
    if (pageName.trim() === '') {
      setError('Le nom de la page ne peut pas être vide.');
      return;
    }
    setError(''); // Réinitialise l'erreur avant l'envoi

    fetch('https://server.matrix.rtvc-cmonde.com/api/create_page', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ page_name: pageName }),
    })
      .then(response => response.json())
      .then(data => { 
        if (data.status === 'success') {
          alert(data.message);
          window.location.href = `/page/${pageName}`; // Redirige vers la nouvelle page
        } else {
          setError(data.message); // Affiche l'erreur reçue du backend
        }
      })
      .catch(() => {
        setError('Erreur lors de la création de la page. Veuillez réessayer.');
      });
  };

  return (
    <div className="create-page-container">
      <h2>Créer une Nouvelle Page</h2>
      <input
        type="text"
        value={pageName}
        onChange={(e) => setPageName(e.target.value)}
        placeholder="Nom de la nouvelle page"
        className="input-field"
      />
      <button className="button button-create" onClick={handleCreatePage}>
        Créer
      </button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default CreatePage;
