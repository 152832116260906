import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/Cell.css';

function Cell({ cellId, cellData }) {
  const [color, setColor] = useState(cellData.color || 'green');
  const [pressTimer, setPressTimer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setColor(cellData.color || 'green');
  }, [cellData]);

  const handleClick = () => {
    console.log('Navigating to:', cellId);
    navigate(`/cell-detail/${cellId}`);
  };

  const handleRightClick = (event) => {
    event.preventDefault(); // Prévenir le menu contextuel par défaut
    const newColor = color === 'green' ? 'red' : 'green';
    setColor(newColor);
    updateCellColor(newColor);
  };

  const handleMouseDown = () => {
    // Définir un timer pour un long press
    const timer = setTimeout(() => {
      const newColor = color === 'gray' ? 'green' : 'gray';
      setColor(newColor);
      updateCellColor(newColor);
    }, 1000); // Temps en millisecondes pour un long press
    setPressTimer(timer);
  };
 
  const handleMouseUp = () => {
    // Annuler le timer quand l'utilisateur relâche le bouton de la souris
    clearTimeout(pressTimer);
    setPressTimer(null);
  };

  const updateCellColor = (newColor) => {
    fetch(`https://server.matrix.rtvc-cmonde.com/api/cells/${cellId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...cellData, color: newColor }),
    });
  };

  return (
    <div
      className="cell"
      style={{ backgroundColor: color }}
      onClick={handleClick}
      onContextMenu={handleRightClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    ></div>
  );
}

export default Cell;
