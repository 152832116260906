import React, { useState, useEffect } from 'react';
import Cell from './Cell';
import AddRowColumn from './AddRowColumn'; // Assurez-vous d'importer le nouveau composant
import '../Styles/Matrix.css';

function Matrix() {
  const [cellsData, setCellsData] = useState({});
  const [rows, setRows] = useState(['Marche avec Dieu', 'Etudes ou Travail', 'Faire des disciples', 'Jeûne']);
  const [columns, setColumns] = useState(['Ide Flore', 'Elisée', 'Dorice', 'Dany William', 'Gilles', 'Diane', 'Salomon', 'Darryl', 'Brayan', 'Loan', 'Ludecie', 'Anne Laure', 'Yann-Arthur', 'Dinette', 'Peguy']);

  useEffect(() => {
    fetch('https://server.matrix.rtvc-cmonde.com/api/cells')
      .then(response => response.json())
      .then(data => setCellsData(data));
  }, []);

  const addRow = (row) => { 
    setRows([...rows, row]);
  };

  const addColumn = (column) => {
    setColumns([...columns, column]);
  };

  return (
    <div className="matrix-container">
      <AddRowColumn onAddRow={addRow} onAddColumn={addColumn} />
      <div className="matrix">
        <table>
          <thead>
            <tr>
              <th></th>
              {columns.map(col => (
                <th key={col}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map(row => (
              <tr key={row}>
                <th>{row}</th>
                {columns.map(col => {
                  const cellId = `${row}-${col}`;
                  const cellData = cellsData[cellId] || {};
                  return (
                    <td key={col}>
                      <Cell cellId={cellId} cellData={cellData} />
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Matrix;
