import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../Styles/DynamicPage.css';

function DynamicPage() {
  const { pageName } = useParams();
  const [cellsData, setCellsData] = useState({});
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [newRow, setNewRow] = useState('');
  const [newColumn, setNewColumn] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`https://server.matrix.rtvc-cmonde.com/api/pages/${pageName}/cells`)
      .then((response) => response.json())
      .then((data) => {
        setCellsData(data.cells || {});
        setRows(data.rows || []);
        setColumns(data.columns || []);
      })
      .catch((error) => console.error('Erreur lors de la récupération des données :', error));
  }, [pageName]);

  const addRow = () => {
    if (newRow.trim() !== '' && !rows.includes(newRow)) {
      const updatedRows = [...rows, newRow];
      setRows(updatedRows);
      setNewRow('');
  
      fetch(`https://server.matrix.rtvc-cmonde.com/api/pages/${encodeURIComponent(pageName)}/update_layout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ row: newRow }),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erreur lors de l’ajout de la ligne');
        }
        console.log('Ligne ajoutée avec succès');
  
        columns.forEach(column => {
          const cellId = `${newRow}-${column}`;
          fetch(`https://server.matrix.rtvc-cmonde.com/api/pages/${pageName}/cells/${cellId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ comments: '', color: 'gray' }),
          })
          .then(cellResponse => {
            if (!cellResponse.ok) {
              throw new Error(`Erreur lors de la création de la cellule ${cellId}`);
            }
            console.log(`Cellule ${cellId} créée avec succès`);
          })
          .catch(error => console.error(`Erreur lors de la création de la cellule ${cellId}:`, error));
        });
      })
      .catch(error => console.error('Erreur lors de l’ajout de la ligne:', error));
    }
  };

  const addColumn = () => {
    if (newColumn.trim() !== '' && !columns.includes(newColumn)) {
      const updatedColumns = [...columns, newColumn];
      setColumns(updatedColumns);
      setNewColumn('');

      fetch(`https://server.matrix.rtvc-cmonde.com/api/pages/${encodeURIComponent(pageName)}/update_layout`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ column: newColumn }),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erreur lors de l’ajout de la colonne');
        }
        console.log('Colonne ajoutée avec succès');
      })
      .catch(error => console.error('Erreur lors de l’ajout de la colonne:', error));
    }
  };

  const updateCellColor = (row, col) => {
    const cellId = `${row}-${col}`;
    const currentData = cellsData[cellId] || { color: 'gray', comments: '' };
    const newColor = currentData.comments ? 'white' : 'gray';
    const updatedData = { ...cellsData, [cellId]: { ...currentData, color: newColor } };
  
    setCellsData(updatedData);
  
    fetch(`https://server.matrix.rtvc-cmonde.com/api/pages/${pageName}/cells/${cellId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedData[cellId]),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour de la cellule');
      }
    })
    .catch(error => console.error('Erreur lors de la mise à jour de la cellule:', error));
  };

  const openCellDetail = (cellId) => {
    navigate(`/cell-detail/${pageName}/${cellId}`);
  };

  const goToHome = () => {
    navigate('/');
  };

  return (
    <div className="dynamic-page-container">
      <header className="dynamic-page-header">
        <h1>Gestion de la Page: {pageName}</h1>
        <button className="button button-home" onClick={goToHome}>
          Retour à l'accueil
        </button>
      </header>

      <div className="input-container">
        <div className="input-section">
          <input
            type="text"
            value={newRow}
            onChange={(e) => setNewRow(e.target.value)}
            placeholder="Nouvelle ligne"
            className="input-field"
          />
          <button className="button button-add" onClick={addRow}>
            Ajouter Ligne
          </button>
        </div>

        <div className="input-section">
          <input
            type="text"
            value={newColumn}
            onChange={(e) => setNewColumn(e.target.value)}
            placeholder="Nouvelle colonne"
            className="input-field"
          />
          <button className="button button-add" onClick={addColumn}>
            Ajouter Colonne
          </button>
        </div>
      </div>

      <table className="dynamic-table">
        <thead>
          <tr>
            <th></th>
            {columns.map((col) => (
              <th key={col}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row}>
              <th>{row}</th>
              {columns.map((col) => {
                const cellId = `${row}-${col}`;
                const cell = cellsData[cellId] || { color: 'gray' };
                const cellColor = cell.comments ? 'white' : 'gray';
                return (
                  <td
                    key={col}
                    style={{ backgroundColor: cellColor }}
                    onClick={() => openCellDetail(cellId)}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      updateCellColor(row, col);
                    }}
                    className="dynamic-cell"
                  ></td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DynamicPage;
