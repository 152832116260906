import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/HomePage.css';

function HomePage() {
  const [pages, setPages] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [reportFormat, setReportFormat] = useState("markdown"); // Format de rapport par défaut
  const [reportContent, setReportContent] = useState(null); // État pour stocker le rapport
  const [prayerReport, setPrayerReport] = useState([]); // État pour stocker le rapport de sujets de prière

  useEffect(() => {
    fetch('https://server.matrix.rtvc-cmonde.com/api/pages')
      .then((response) => response.json())
      .then((data) => setPages(data))
      .catch((error) => console.error('Erreur lors du chargement des pages:', error));
  }, []);

  const handleSelectPage = (pageName) => {
    setSelectedPages(prevSelected =>
      prevSelected.includes(pageName)
        ? prevSelected.filter(page => page !== pageName)
        : [...prevSelected, pageName]
    );
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedPages(!selectAll ? pages : []);
  };

  const generateReport = () => {
    const pagesToReport = selectAll ? pages : selectedPages;
    fetch('https://server.matrix.rtvc-cmonde.com/api/generate_report', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ pages: pagesToReport, format: reportFormat })
    })
      .then(response => {
        if (reportFormat === "pdf") {
          return response.blob();
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (reportFormat === "pdf") {
          const url = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "rapport.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          setReportContent(data.report);
        }
      })
      .catch(error => console.error('Erreur lors de la génération du rapport:', error));
  };

  // Nouvelle fonction pour extraire les sujets de prière
  const extractPrayerRequests = () => {
    fetch('https://server.matrix.rtvc-cmonde.com/api/extract_prayer_requests', {
      method: 'POST'
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          setPrayerReport(data.report); // Stocke le rapport des sujets de prière
        } else {
          alert("Erreur lors de l'extraction des sujets de prière");
        }
      })
      .catch(error => console.error('Erreur lors de l\'extraction des sujets de prière:', error));
  };

  return (
    <div className="home-page-container">
      <header className="home-page-header">
        <h1>Gestion des Pages</h1>
        <Link to="/create" className="button button-create">
          Créer une Nouvelle Page
        </Link>
        
        <label>
          Format du rapport :
          <select value={reportFormat} onChange={(e) => setReportFormat(e.target.value)}>
            <option value="markdown">Markdown</option>
            <option value="html">HTML</option>
            <option value="json">JSON</option>
            <option value="csv">CSV</option>
            <option value="text">Texte</option>
            <option value="pdf">PDF</option>
          </select>
        </label>
        
        <button onClick={generateReport} className="button button-report" disabled={selectedPages.length === 0 && !selectAll}>
          Générer le Rapport
        </button>

        {/* Nouveau bouton pour extraire les sujets de prière */}
        <button onClick={extractPrayerRequests} className="button button-prayer" style={{ backgroundColor: "#FF6347", color: "white" }}>
          Sujets de prière
        </button>
      </header>
      
      <div className="page-list-container">
        <h2>Liste des Pages Créées</h2>
        <label>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
          />
          Tout sélectionner
        </label>
        {pages.length === 0 ? (
          <p>Aucune page créée pour le moment.</p>
        ) : (
          <ul className="page-list">
            {pages.map((pageName) => (
              <li key={pageName} className="page-item">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedPages.includes(pageName)}
                    onChange={() => handleSelectPage(pageName)}
                  />
                  {pageName}
                </label>
                <Link to={`/page/${pageName}`} className="page-link">
                  {pageName}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Affichage du rapport des sujets de prière */}
      {prayerReport.length > 0 && (
        <div className="prayer-report" style={{ marginTop: "20px", padding: "20px", border: "1px solid #ddd", borderRadius: "5px", backgroundColor: "#f9f9f9" }}>
          <h2>Rapport des Sujets de Prière</h2>
          <ul>
            {prayerReport.map((item, index) => (
              <li key={index}>
                <strong>{item.source}:</strong> {item.text}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Affichage du rapport généré */}
      {reportContent && (
        <div className="report-content" style={{ marginTop: "20px", padding: "20px", border: "1px solid #ddd", borderRadius: "5px", backgroundColor: "#f9f9f9" }}>
          <h2>Rapport Généré</h2>
          {reportFormat === "html" ? (
            <div dangerouslySetInnerHTML={{ __html: reportContent }} />
          ) : (
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
              {typeof reportContent === "object" ? JSON.stringify(reportContent, null, 2) : reportContent}
            </pre>
          )}
        </div>
      )}
    </div>
  );
}

export default HomePage;
