import React, { useState } from 'react';

function AddRowColumn({ onAddRow, onAddColumn }) {
  const [newRow, setNewRow] = useState('');
  const [newColumn, setNewColumn] = useState('');

  const handleAddRow = () => {
    if (newRow.trim() !== '') {
      onAddRow(newRow);
      setNewRow(''); // Réinitialise le champ après l'ajout
    }
  };

  const handleAddColumn = () => {
    if (newColumn.trim() !== '') {
      onAddColumn(newColumn);
      setNewColumn(''); // Réinitialise le champ après l'ajout
    }
  };

  return (  
    <div className="add-row-column">
      <div>
        <input
          type="text"
          placeholder="Nouvelle ligne"
          value={newRow}
          onChange={(e) => setNewRow(e.target.value)}
        />
        <button onClick={handleAddRow}>Ajouter Ligne</button>
      </div>
      <div>
        <input
          type="text"
          placeholder="Nouvelle colonne"
          value={newColumn}
          onChange={(e) => setNewColumn(e.target.value)}
        />
        <button onClick={handleAddColumn}>Ajouter Colonne</button>
      </div>
    </div>
  );
}

export default AddRowColumn;
