import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Matrix from './Components/Matrix';
import HomePage from './Components/HomePage'; // Importation de HomePage
import CellDetail from './Components/CellDetail';
import DynamicPage from './Components/DynamicPage'; // Assurez-vous que le chemin vers votre composant est correct
import CreatePage from './Components/CreatePage'; // Si vous avez un composant pour créer les pages

function App() {
  return (
    <Router>
      <Routes>
        {/* Route de la page d'accueil */}
        <Route path="/" element={<HomePage />} />
        {/* Route pour créer une nouvelle page */}
        <Route path="/create" element={<CreatePage />} />
        
        {/* Route dynamique pour afficher une page spécifique */}
        <Route path="/page/:pageName" element={<DynamicPage />} />
        <Route path="/" element={<Matrix />} />
        {/* <Route path="/cell-detail/:cellId" element={<CellDetail />} /> */}
        <Route path="/cell-detail/:pageName/:cellId" element={<CellDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
